<script>
import Layout from "../layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Downloads': 'Downloads',
    },
    es: {
      'Downloads': 'Descargas',
    }
  },
  components: { Layout },
  data() {
    return {
      downloads: ''
    };
  },
  methods: {
    getList: function () {
      api
        .get('downloads')
        .then((response) => {
          if (response.data.status == 'success') {
            this.downloads = response.data.list
          }
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Downloads') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-centered table-nowrap">
                <tbody>
                  <tr v-for="download in downloads" :key="download.index">
                    <td><h5 class="m-0">{{ download.title }}</h5></td>
                    <td class="text-right"><a class="btn btn-danger" target="_blank" :href="download.arquivo_url">Download</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
